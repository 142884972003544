<template>
  <el-dialog
    title="退单"
    :visible.sync="visible"
    width="580px"
    @close="dialogClose"
  >
    <div class="txtBox">
      <label style="line-height: 30px">退票手续费(%)：</label>
      <el-select v-model="serviceCharge" class="charterSelect">
        <el-option
          v-for="item in serviceChargeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <div class="txtBox">
      <label> <span class="colorError">*</span>原因： </label>
      <el-input
        v-model="remark"
        type="textarea"
        :show-word-limit="true"
        placeholder="请输入退单原因"
        maxlength="200"
      />
    </div>
    <div class="txtBox mt20">
      <label>短信预览：</label>
      <div class="msgBox">
        <span>【广运神马】您的租车订单</span>
        <span class="colorInfo">{{ base.id }}</span>
        <span>已被平台取消，原因：</span>
        <span class="colorInfo">{{ remark }}</span>
        <span
          >。您支付的金额将在3个工作日退回您的账户，如有疑问，请联系客服：{{
            tel
          }}</span
        >
      </div>
    </div>

    <div class="footerBtn">
      <el-button size="small" :loading="loading" @click="handleCancel"
        >取消</el-button
      >
      <el-button
        type="primary"
        size="small"
        :loading="loading"
        @click="handleSubmit"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { refundOrder } from "@/api/lib/api";
export default {
  data() {
    return {
      serviceCharge: 0,
      serviceChargeList: [{ label: "0", value: 0 }],
      visible: false,
      loading: false,
      remark: "",
      tel: "",
      base: {},
    };
  },
  mounted() {
    this.recurSion(0);
  },
  methods: {
    dialogClose() {
      this.serviceCharge = 0;
      this.remark = "";
    },
    recurSion(num) {
      if (num < 90) {
        let Numb = (num += 5);
        this.serviceChargeList.push({
          label: Numb,
          value: Numb,
        });
        this.recurSion(Numb);
      } else {
        return;
      }
    },
    show(row = {}) {
      this.visible = true;
      this.loading = false;
      let phone = row.carServiceProvider
        ? row.carServiceProvider.orderReminderPhone
        : [];
      this.tel = phone.join(",");
      this.base = { ...row };
    },
    handleCancel() {
      this.remark = "";
      this.tel = "";
      this.base = {};
      this.visible = false;
    },
    handleSubmit() {
      let remark = this.remark;
      if (remark === "") {
        this.$message.error("请输入退单原因！");
        return;
      }
      this.loading = true;
      let orderId = this.base.id;
      let refundProportion = this.serviceCharge;
      let reason = this.remark;
      let params = {
        orderId,
        refundProportion,
        reason,
      };
      refundOrder(params)
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.handleCancel();
            this.$emit("cancelFinsh");
            return;
          }
          this.$message.error(res.msg);
        })
        .finally(() => [(this.loading = false)]);
    },
  },
};
</script>

<style lang="scss" scoped>
.charterSelect {
  width: 200px;
}
.footerBtn {
  margin-top: 24px;
  text-align: right;
}
.colorError {
  color: #f56c6c;
}
.colorInfo {
  color: #409eff;
}
.txtBox {
  display: flex;
  margin-bottom: 20px;
  label {
    width: 120px;
    text-align: right;
    flex-shrink: 0;
  }
  & > div {
    flex: 1;
  }
}
.mt20 {
  margin-top: 20px;
}
.msgBox {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 5px 15px;
  line-height: 22px;
}
</style>
