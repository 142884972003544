var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "退单", visible: _vm.visible, width: "580px" },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.dialogClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "txtBox" },
        [
          _c("label", { staticStyle: { "line-height": "30px" } }, [
            _vm._v("退票手续费(%)："),
          ]),
          _c(
            "el-select",
            {
              staticClass: "charterSelect",
              model: {
                value: _vm.serviceCharge,
                callback: function ($$v) {
                  _vm.serviceCharge = $$v
                },
                expression: "serviceCharge",
              },
            },
            _vm._l(_vm.serviceChargeList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "txtBox" },
        [
          _c("label", [
            _c("span", { staticClass: "colorError" }, [_vm._v("*")]),
            _vm._v("原因： "),
          ]),
          _c("el-input", {
            attrs: {
              type: "textarea",
              "show-word-limit": true,
              placeholder: "请输入退单原因",
              maxlength: "200",
            },
            model: {
              value: _vm.remark,
              callback: function ($$v) {
                _vm.remark = $$v
              },
              expression: "remark",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "txtBox mt20" }, [
        _c("label", [_vm._v("短信预览：")]),
        _c("div", { staticClass: "msgBox" }, [
          _c("span", [_vm._v("【广运神马】您的租车订单")]),
          _c("span", { staticClass: "colorInfo" }, [
            _vm._v(_vm._s(_vm.base.id)),
          ]),
          _c("span", [_vm._v("已被平台取消，原因：")]),
          _c("span", { staticClass: "colorInfo" }, [
            _vm._v(_vm._s(_vm.remark)),
          ]),
          _c("span", [
            _vm._v(
              "。您支付的金额将在3个工作日退回您的账户，如有疑问，请联系客服：" +
                _vm._s(_vm.tel)
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "footerBtn" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", loading: _vm.loading },
              on: { click: _vm.handleCancel },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", loading: _vm.loading },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }